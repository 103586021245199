<template>
    <footer class="footer-container">
        <div class="container">
            <div class="footer-top">
                <FooterMenu :menu="item" v-for="(item, index) in menu" :key="index" />
                <div class="footer-category">
                    <div class="footer__menu--title">Categories</div>
                    <SelectComponent
                        v-model="selected"
                        :options="langLiset"
                    />
                </div>
                <FooterSubscribe/>
            </div>
            <div class="footer-bottom">
                <div class="footer-bottom--left">
                    <div class="logo">
                        <img :src="logo" alt="">
                    </div>
                    <p>© 2024 Business search</p>
                </div>
                <div class="footer-bottom--right">
                    <div class="footer-bottom--soc">
                        <a class="footer-bottom--icon" href="">
                            <svg width="25.479004" height="24.534912" viewBox="0 0 25.479 24.5349" fill="none">
                                <defs/>
                                <path id="Vector"
                                      d="M20.97 5.81C20.97 6.61 20.3 7.25 19.48 7.25C18.65 7.25 17.99 6.61 17.99 5.81C17.99 5.02 18.65 4.38 19.48 4.38C20.3 4.38 20.97 5.02 20.97 5.81Z"
                                      fill="#23152A" fill-opacity="1.000000" fill-rule="evenodd"/>
                                <path id="Vector"
                                      d="M12.84 6.22C9.38 6.22 6.56 8.93 6.56 12.26C6.56 15.59 9.38 18.3 12.84 18.3C16.3 18.3 19.11 15.59 19.11 12.26C19.11 8.93 16.3 6.22 12.84 6.22ZM12.84 16.13C10.62 16.13 8.82 14.4 8.82 12.26C8.82 10.13 10.62 8.39 12.84 8.39C15.06 8.39 16.86 10.13 16.86 12.26C16.86 14.4 15.06 16.13 12.84 16.13Z"
                                      fill="#23152A" fill-opacity="1.000000" fill-rule="nonzero"/>
                                <path id="Vector"
                                      d="M17.82 24.53L7.65 24.53C3.43 24.53 0 21.22 0 17.16L0 7.36C0 3.3 3.43 0 7.65 0L17.82 0C22.04 0 25.47 3.3 25.47 7.36L25.47 17.16C25.47 21.22 22.04 24.53 17.82 24.53ZM7.65 2.3C4.75 2.3 2.39 4.57 2.39 7.36L2.39 17.16C2.39 19.95 4.75 22.22 7.65 22.22L17.82 22.22C20.72 22.22 23.08 19.95 23.08 17.16L23.08 7.36C23.08 4.57 20.72 2.3 17.82 2.3L7.65 2.3Z"
                                      fill="#23152A" fill-opacity="1.000000" fill-rule="nonzero"/>
                            </svg>
                        </a>
                        <a class="footer-bottom--icon" href="">
                            <svg width="11.522461" height="23.780029" viewBox="0 0 11.5225 23.78" fill="none">
                                <defs/>
                                <path id="Vector"
                                      d="M7.66 23.78L7.66 11.88L11.07 11.88L11.52 7.79L7.66 7.79L7.66 5.73C7.66 4.67 7.77 4.09 9.36 4.09L11.49 4.09L11.49 0L8.08 0C3.99 0 2.55 1.98 2.55 5.33L2.55 7.79L0 7.79L0 11.88L2.55 11.88L2.55 23.78L7.66 23.78Z"
                                      fill="#23152A" fill-opacity="1.000000" fill-rule="nonzero"/>
                            </svg>
                        </a>
                        <a class="footer-bottom--icon" href="">
                            <svg width="21.972656" height="17.485840" viewBox="0 0 21.9727 17.4858" fill="none">
                                <defs/>
                                <path id="Vector"
                                      d="M10.66 4.65L10.71 5.4L9.92 5.31C7.03 4.96 4.5 3.75 2.36 1.73L1.31 0.73L1.04 1.47C0.47 3.12 0.84 4.86 2.03 6.04C2.66 6.68 2.52 6.78 1.42 6.39C1.04 6.27 0.71 6.17 0.68 6.22C0.57 6.33 0.95 7.73 1.25 8.29C1.66 9.06 2.5 9.82 3.42 10.26L4.2 10.62L3.28 10.64C2.39 10.64 2.36 10.65 2.46 10.97C2.77 11.98 4.03 13.04 5.42 13.51L6.41 13.83L5.55 14.32C4.28 15.03 2.79 15.44 1.3 15.47C0.58 15.48 0 15.54 0 15.59C0 15.74 1.93 16.61 3.06 16.95C6.44 17.95 10.46 17.52 13.47 15.81C15.62 14.59 17.76 12.16 18.76 9.82C19.3 8.57 19.84 6.28 19.84 5.19C19.84 4.48 19.89 4.38 20.78 3.54C21.3 3.04 21.79 2.5 21.89 2.35C22.05 2.05 22.03 2.05 21.22 2.32C19.87 2.78 19.68 2.72 20.35 2.02C20.84 1.53 21.43 0.63 21.43 0.37C21.43 0.32 21.19 0.4 20.92 0.54C20.63 0.7 20 0.93 19.52 1.07L18.67 1.33L17.89 0.82C17.46 0.54 16.86 0.23 16.54 0.14C15.73 -0.08 14.49 -0.05 13.76 0.2C11.78 0.9 10.52 2.69 10.66 4.65Z"
                                      fill="#23152A" fill-opacity="1.000000" fill-rule="nonzero"/>
                            </svg>
                        </a>
                    </div>
                    <div class="footer-bottom--links">
                        <p>© 2024 Business search</p>
                        <a href="">Privacy Policy</a>
                        <a href="">Terms of Service</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {reactive} from "vue";
import SelectComponent from "@/components/SelectComponent";
import FooterSubscribe from "@/components/Footer/FooterSubscribe";
import FooterMenu from "@/components/Footer/FooterMenu";

const logo = require('@/assets/img/logo.png');

const selected = null;

const menu = reactive([
    {
        title: 'About',
        list: [
            {title: 'Careers', link: '#'},
            {title: 'Press & News', link: '#'},
            {title: 'Privacy Policy', link: '#'},
            {title: 'Terms of Service', link: '#'}
        ]
    },
    {
        title: 'Support',
        list: [
            {title: 'Help & Support', link: '#'},
            {title: 'Trust & Safety', link: '#'},
            {title: 'Business search Guide', link: '#'},
        ]
    }
]);
//
// const menu1 = reactive({
//     title: 'About',
//     list: [
//         {title: 'Careers', link: '#'},
//         {title: 'Press & News', link: '#'},
//         {title: 'Privacy Policy', link: '#'},
//         {title: 'Terms of Service', link: '#'}
//     ]
// });
//
// const menu2 = reactive({
//     title: 'About',
//     list: [
//         {title: 'Help & Support', link: '#'},
//         {title: 'Trust & Safety', link: '#'},
//         {title: 'Business search Guide', link: '#'},
//     ]
// });

const langLiset = reactive([
    {label: 'Украiнська', value: 'ua'},
    {label: 'Русский', value: 'ru'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
    {label: 'English', value: 'en'},
])

</script>

<style lang="scss">
.control {
    border-radius: 8px;
    background: rgb(255, 255, 255);
    box-shadow: unset !important;
    border-color: transparent !important;
    height: 40px;
}

</style>
