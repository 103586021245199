<!-- src/components/SelectComponent.vue -->
<template>
  <v-select v-model="selectedOption" :isSearchable="false" :options="options" placeholder="Select your chose"></v-select>
</template>

<script>
import VSelect from 'vue3-select-component';

export default {
  components: {
    VSelect
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedOption: null
    }
  }
}
</script>

<style lang="scss">
.menu {
  box-sizing: border-box;
  border: 0.5px solid rgb(203, 203, 203);
  border-radius: 10px !important;
  background: rgb(255, 255, 255);
  padding: 19px 28px !important;
  width: 224px !important;
  max-height: 249px !important;
  overflow: auto;
  left: 50% !important;
  transform: translate(-50%, 0);

  .menu-option {
    max-width: 100%;
    border-bottom: 1px solid rgb(221, 221, 221);
    padding: 6px 0 5px 0;
    box-sizing: border-box;
    color: rgb(128, 128, 128);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 89.5%;
    border-radius: unset;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 5px !important;

    &.focused {
      background-color: rgb(245, 236, 254);
    }

    &:hover:not(.focused) {
      font-weight: 600;
      background-color: rgb(245, 236, 254);
    }
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 14px;
    background: rgb(217, 217, 217);
  }
}
.clear-button{
  display: none !important;
}
.single-value{
  color: rgb(124, 124, 124) !important;
  font-family: 'NuberNext' !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}
</style>
