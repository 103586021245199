<template>
  <div class="header-menu">
    <ul class="desk-menu">
      <li>
        <router-link to="/profile/info">About</router-link>
      </li>
      <li>
        <router-link to="/profile/history">History</router-link>
      </li>
      <li>
        <router-link to="/profile/favorite">Favorite</router-link>
      </li>
      <li>
        <router-link to="/profile/setting">Settings</router-link>
      </li>
      <li>
        <router-link to="/search">Search</router-link>
      </li>
      <li>
        <router-link to="/categories">Services</router-link>
      </li>
      <li>
        <router-link to="#">Careers</router-link>
      </li>
      <li>
        <router-link to="#">Contacts</router-link>
      </li>
    </ul>
    <div class="mob-menu">
      <v-menu class="mob-menu__wrap" :location="location" :close-on-content-click="false" >
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon v-bind="props"></v-app-bar-nav-icon>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index">
            <v-list-item-title>
              <router-link :to="item.link">
                {{ item.title }}
              </router-link>
            </v-list-item-title>
            <template v-slot:append v-if="item.langList">
              <v-icon icon="mdi-chevron-right" size="x-small"></v-icon>
            </template>
            <v-menu
                v-if="item.langList"
                open-on-click
                activator="parent"
                submenu
                height="270"
                class="lang-mob"
            >
              <v-list>
                <v-list-item v-for="lang in item.langList" :key="lang" link>
                  <v-list-item-title>{{ lang.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script setup>
import {reactive} from "vue";

const items = reactive([
  {title: 'About', link: '/profile/info'},
  {title: 'History', link: '/profile/history'},
  {title: 'Favorite', link: '/profile/favorite'},
  {title: 'Setting', link: '/profile/setting'},
  {title: 'Search', link: '/search'},
  {title: 'Services', link: '/categories'},
  {title: 'Careers', link: '/'},
  {title: 'Contacts', link: '/'},
  {title: 'Lang', langList: [
      {label: 'Українська', value: 'ua'},
      {label: 'Русский', value: 'ru'},
      {label: 'Українська', value: 'ua'},
      {label: 'Русский', value: 'ru'},
      {label: 'Українська', value: 'ua'},
      {label: 'Русский', value: 'ru'},
      {label: 'Українська', value: 'ua'},
      {label: 'Русский', value: 'ru'},
      {label: 'Українська', value: 'ua'},
      {label: 'Русский', value: 'ru'},
      {label: 'Українська', value: 'ua'},
      {label: 'Русский', value: 'ru'},
      {label: 'Українська', value: 'ua'},
      {label: 'Русский', value: 'ru'},
      {label: 'English', value: 'en'}
    ], link: '/categories'},
])
</script>

<style lang="scss" scoped>
.hide-desktop{
  display: none;
  @media(max-width: 767px) {
    display: block;
  }
}
</style>