import { createApp } from 'vue'
import App from './App.vue'
import vuetify from "@/plugins/vuetify"
import 'swiper/swiper-bundle.css'
import '@vueform/multiselect/themes/default.css'
import './assets/css/style.scss'
import router from './router'
import VueEasyLightbox from "vue-easy-lightbox";

createApp(App)
    .use(router)
    .use(vuetify)
    .use(VueEasyLightbox)
    .mount('#app')
